import { useState } from 'react';
import './App.css';
import RoulettePro from 'react-roulette-pro';
import 'react-roulette-pro/dist/index.css';
import Confetti from 'react-confetti'
import Swal from 'sweetalert2';
import queryString from "query-string"

const prizes = [
  {
    image: 'https://i.ibb.co/VpCpjLb/image-20.webp',
    text: 'Surviving Mars'
  },
  {
    image: 'https://i.ibb.co/yXYz4sV/image-9.webp',
    text: 'Black Myth: Wukong'
  },
  {
    image: 'https://i.ibb.co/FhxWq57/image-12.webp',
    text: 'Necroking'
  },
  {
    image: 'https://i.ibb.co/94mp0pR/image-10.webp',
    text: 'Marvel’s Spider-Man Remastered'
  },
  {
    image: 'https://i.ibb.co/RS8sKc7/image-11.webp',
    text: 'Ghost of Tsushima'
  },
  {
    image: 'https://i.ibb.co/T1HrsZg/image-8.webp',
    text: 'Monster Hunter Wilds'
  },
  {
    image: 'https://i.ibb.co/qrq5hQt/image-13.webp',
    text: 'Do Not Feed the Monkeys'
  },
  {
    image: 'https://i.ibb.co/Yj8HMG0/image-25.webp',
    text: 'Postal Redux'
  },
  {
    image: 'https://i.ibb.co/wK7tRGX/image-7.webp',
    text: 'Frostpunk 2'
  },
  {
    image: 'https://i.ibb.co/n3FDSYt/image-6.webp',
    text: 'Scorn: Deluxe Edition'
  },
  {
    image: 'https://i.ibb.co/ByjLb8f/image-19.webp',
    text: 'Tekken 8'
  },
  {
    image: 'https://i.ibb.co/j424Mwv/image-5.webp',
    text: 'God of War: Ragnarok'
  },
  {
    image: 'https://i.ibb.co/JjwnB6b/image-24.webp',
    text: 'Distrust: Polar Survival'
  },
  {
    image: 'https://i.ibb.co/jzNM8gc/image-4.webp',
    text: 'Metal Gear Rising: Revengeance'
  },
  {
    image: 'https://i.ibb.co/PGxPvxQ/image-21.webp',
    text: 'Punch Club'
  },
  {
    image: 'https://i.ibb.co/FWLX6Lp/image-3.webp',
    text: 'Dark Souls Remastered'
  },
  {
    image: 'https://i.ibb.co/Yd1C4L2/image-23.webp',
    text: 'Beholder'
  },
  {
    image: 'https://i.ibb.co/F6RDfRk/image-16.webp',
    text: 'Mad Max'
  },
  {
    image: 'https://i.ibb.co/wYzLMg7/image-2.webp',
    text: 'Super Hot'
  },
  {
    image: 'https://i.ibb.co/GtPxXVR/image-1.webp',
    text: 'State of Mind'
  },
  {
    image: 'https://i.ibb.co/cgRmWRF/image.webp',
    text: 'Cyberpunk 2077'
  },
  {
    image: 'https://i.ibb.co/V2ZY470/image-14.webp',
    text: 'Final Station'
  },
  {
    image: 'https://i.ibb.co/hmxFBw8/image-15.webp',
    text: 'Quantum Break'
  },
  {
    image: 'https://i.ibb.co/RhG4PH6/image-17.webp',
    text: 'Horizon Zero Dawn'
  },
  {
    image: 'https://i.ibb.co/MR02KxP/image-22.webp',
    text: 'Graveyard Keeper'
  },
  {
    image: 'https://i.ibb.co/DwcNVB6/image-18.webp',
    text: 'HellDivers 2'
  }
];

const reproductionArray = (array : any = [], length = 0) => [
  ...Array(length)
    .fill('_')
    .map(() => array[Math.floor(Math.random() * array.length)]),
];

const generateId = () =>
  `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

const winnerIdx = 15

function App() {
  // Пример запроса:
  // http://localhost:3000/?name=QmVob2xkZXI%3D&code=SExHV0YtOTg2TDYtWUI5MEY%3D&img=aHR0cHM6Ly9pLmliYi5jby9WcENwakxiL2ltYWdlLTIwLndlYnA%3D
  const queryParams = queryString.parse(window.location.search)
  const itemName = atob(decodeURI(queryParams.name?.toString()!))
  const itemCode = atob(decodeURI(queryParams.code?.toString()!))
  const itemImg = atob(decodeURI(queryParams.img?.toString()!))
  prizes.splice(winnerIdx, 0, {
    image: itemImg,
    text: itemName,
  })

  const reproducedPrizeList = [
    ...prizes,
    ...reproductionArray(prizes, prizes.length * 3),
    ...prizes,
    ...reproductionArray(prizes, prizes.length),
  ];
  const prizeList = reproducedPrizeList.map((prize) => ({
    ...prize,
    id: typeof crypto.randomUUID === 'function' ? crypto.randomUUID() : generateId(),
  }));

  const [start, setStart] = useState(false);

  const prizeIndex = prizes.length * 4 + winnerIdx;

  const handleStart = () => {
    setStart((prevState) => !prevState);
  };

  const [showConfetti, setShowConfetti] = useState(false)

  const handlePrizeDefined = () => {
    setShowConfetti(true)
    Swal.fire({
      title: "Поздравляем!",
      html: `
    Вы выиграли игру <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-steam" viewBox="0 0 16 16">
  <path d="M.329 10.333A8.01 8.01 0 0 0 7.99 16C12.414 16 16 12.418 16 8s-3.586-8-8.009-8A8.006 8.006 0 0 0 0 7.468l.003.006 4.304 1.769A2.2 2.2 0 0 1 5.62 8.88l1.96-2.844-.001-.04a3.046 3.046 0 0 1 3.042-3.043 3.046 3.046 0 0 1 3.042 3.043 3.047 3.047 0 0 1-3.111 3.044l-2.804 2a2.223 2.223 0 0 1-3.075 2.11 2.22 2.22 0 0 1-1.312-1.568L.33 10.333Z"/>
  <path d="M4.868 12.683a1.715 1.715 0 0 0 1.318-3.165 1.7 1.7 0 0 0-1.263-.02l1.023.424a1.261 1.261 0 1 1-.97 2.33l-.99-.41a1.7 1.7 0 0 0 .882.84Zm3.726-6.687a2.03 2.03 0 0 0 2.027 2.029 2.03 2.03 0 0 0 2.027-2.029 2.03 2.03 0 0 0-2.027-2.027 2.03 2.03 0 0 0-2.027 2.027m2.03-1.527a1.524 1.524 0 1 1-.002 3.048 1.524 1.524 0 0 1 .002-3.048"/>
</svg> <b class="game-name-value">${itemName}</b>
    <div>
      <span id="copyButton" class="input-group-addon btn" title="Click to copy" onclick="onCopyGameCode()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
        </svg>
      </span>
      <span class="game-code-value"><b>${itemCode}</b></span>
    </div>
  `,
      icon: "success",
      confirmButtonColor: "#007bff",
      confirmButtonText: `Закрыть`,
      preConfirm: function() {
        Telegram.WebApp.close()
      }
    });
  };

  return (
    <div className='App'>
      {showConfetti && (
        <Confetti/>
      )}
      <div className='roulette-main-container pt-5 pb-2 pr-2 pl-2 mr-3 ml-3'>
        <RoulettePro
          prizes={prizeList}
          prizeIndex={prizeIndex}
          start={start}
          onPrizeDefined={handlePrizeDefined}
          defaultDesignOptions={{hideCenterDelimiter: false, prizesWithText: true}}
          spinningTime={5}
          options={{
            withoutAnimation: false
          }}
        />
        <div className="container mt-3 mb-3">
          <div className="row p-2">
            <div className="col text-center">
              <button className="start-btn text-center" onClick={handleStart} disabled={start} style={{backgroundColor: start?'#e4e4e4':'#007bff'}}>🎁 Крутить рулетку</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
